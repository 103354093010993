<template>
	<div :class="$style.list">
		<div
			:class="$style.listEl"
			v-for="meal in meals"
			:key="meal.id"
			@click="$emit('detailMeal', meal.id)"
		>
			<img :src="meal.picture"/>
			<div :class="$style.info">
				<div :class="$style.name">{{ meal.name }}</div>
				<div :class="$style.weight">{{ meal.weight }} гр.</div>
			</div>
			<button
				:class="$style.plus"
				@click.stop="showPopup({event:$event, productId:meal.id})"
			>+
			</button>
		</div>
	</div>
</template>
<script>
import {mapMutations, mapState, mapGetters} from "vuex";

export default {
	props: ['meals'],
	methods: mapMutations('lkmenu', ['showPopup']),
}
</script>
<style
	lang="scss"
	module
>
.list
{
	margin-top: 10px;
	padding: 5px;
	/*width: 100%;*/
	min-width: 0;
}

.listEl
{
	margin-bottom: 10px;
	display: grid;
	grid-template-columns: 60px 1fr 40px;
	gap: 5px;
	background: white;
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 14px rgba(0, 0, 0, 0.08);
	border-radius: 8px;
	height: 82px;

	img
	{
		margin: auto;
		width: 54px;
		height: 48px;
		object-fit: contain;
	}

	.plus
	{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 24px;
		height: 24px;
		background-color: $green;
		color: white;
		border-radius: 16px;
		text-align: center;
		padding-bottom: 2px;
		padding-top: 4px;
		align-self: center;
		justify-self: center;
		line-height: 1px;
	}
}

.name
{
	font-family: $mainFontMedium;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 120%;
	overflow-wrap: break-word;
}

.weight
{
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 14px;
	letter-spacing: 0em;
	text-align: left;
	color: $greyText;
	margin-top: 5px;
}

.info
{
	margin: auto;
	margin-left: 0;
}

@media (max-width: 768px)
{
	.name
	{
		font-size: 12px;
	}
}
</style>
