<template>
	<div
		:class="$style.menuCalendar"
		v-if="showCalendar"
	>
		<DateFilter
			class="date-filter"
			:date="currentDate"
			type="Меню"
			:forCalendar="true"
			@change="setCurrentDate"
		/>
		<MenuCalendar
			:curDate="currentDate"
			:checkLunches="true"
			@change="setCalendar(false);setCurrentDate($event);loadActivity()"
		/>
	</div>
	<div
		:class="{[$style.wrapper]:true, [$style.loading]:loading}"
		v-else
	>
		<span
			v-if="error"
			:class="$style.error"
		>{{ error }}</span>
		<template v-else-if="showProductCalendar">
			<DateFilter
				:class="$style.dateFilter"
				:date="currentDate"
				:type="'Меню'"
				:forCalendar="true"
				@change="setCurrentDate"
			/>
			<MenuCalendar
				:class="$style.productCalendar"
				:addProducts="true"
				:curDate="currentDate"
				@change="setProductCalendar(false); currentDate = $event"
			/>
		</template>
		<template v-else>
			<DateFilter
				:class="$style.date"
				@change="setCurrentDate($event);loadActivity()"
				type="Меню"
				:date="currentDate"
				:warning="lunchProblem.any"
			/>
			<button
				:class="$style.calendar"
				@click="setCalendar(true)"
			></button>
			<MealPeriodType
				:class="$style.periodType"
				:currentPeriodType="getPeriodType"
				@changeCurrentPeriodType="changeCurrentPeriodType($event)"
			/>
			<MealTypeList
				:class="$style.mealType"
				v-if="isReady"
				:currentMealGroup="currentMealGroup"
				:mealTypes="mealTypes"
				:modeActive="true"
				@setCurrentMealGroup="setCurrentMealGroup($event)"
				@scrollToGroup="scrollToGroup"
				@scrollToGroupBtn="scrollToGroupBtn"
			/>
			<MealList
				:class="$style.meals"
				:warning="lunchProblem.any"
				v-if="isReady"
			/>
		</template>
		<MealDetail
			v-if="detailProductId"
			:class="$style.detail"
			:showProductCalendar="showProductCalendar"
			@showProductCalendar="setProductCalendar(true)"
			@closeProductCalendar="setProductCalendar(false)"
		/>
		<MealAddToMenu
			v-show="!detailProductId && isReady"
			:class="$style.detail"
		/>
		<Popup/>
	</div>
</template>

<script>
import MealList from '@/components/block/Rest/MealListOld.vue'
import MealDetail from '@/components/block/Rest/MealDetail.vue'
import DateFilter from '@/components/block/Rest/DateFilter.vue'
import MealAddToMenu from '@/components/block/Rest/MealAddToMenu.vue'
import MealPeriodType from '@/components/block/Rest/MealPeriodType.vue'
import MealTypeList from '@/components/block/Rest/MealTypeList.vue'
import MenuCalendar from '@/components/block/Rest/MenuCalendar.vue'
import {mapMutations, mapState, mapActions, mapGetters} from "vuex";

import Popup from '@/components/block/Rest/Popup.vue'
import axios from "axios";
import qs from "qs";

export default {
	data()
	{
		return {
			interval: null,
		}
	},
	components: {MealList, MealDetail, DateFilter, MealAddToMenu, MealPeriodType, MealTypeList, MenuCalendar, Popup},
	async mounted()
	{
		await this.init('lunch');
	},
	computed: {
		...mapState('lkmenu', ['currentDate', 'detailProductId', 'isReady', 'loading', 'error', 'showCalendar', 'showProductCalendar', 'currentMealGroup']),
		...mapGetters('lkmenu', ['lunchProblem', 'mealTypes', 'getPeriodType'])
	},
	methods: {
		...mapActions('lkmenu', ['init', 'loadActivity', 'changePeriodType']),
		...mapMutations('lkmenu', ['setCurrentDate', 'setCalendar', 'setProductCalendar', 'setCurrentMealGroup', 'setSwitchMealGroupProcess', 'setCurrentPeriodType']),
		async checkAuth()
		{
			let data = qs.stringify({'onlyCheckAuth': true});
			let result = await axios.post('/auth/isLogged/', data);
			if (!result.data.success)
			{
				clearInterval(this.interval);
				this.$store.commit('setUser', '');
			}
		},
		async scrollToGroup(group)
		{
			let target = document.querySelector(`[data-group=${group}]`),
				wrapper = document.querySelector('._meal-groups-wrapper');

			await this.setSwitchMealGroupProcess(true);

			wrapper.scrollTop = target.offsetTop - wrapper.offsetTop + 1;

			setTimeout(() =>
			{
				this.setSwitchMealGroupProcess(false);
			}, 10);
		},
		scrollToGroupBtn(group)
		{
			let target = document.querySelector(`[data-group-btn=${group}]`);
			if (target)
				target.scrollIntoView();
		},
		changeCurrentPeriodType(type)
		{
			this.setCurrentPeriodType(type);
			this.init(type);
		}
	},
	beforeMount()
	{
		this.interval = setInterval(this.checkAuth, 3000);
	},
	beforeDestroy()
	{
		clearInterval(this.interval);
	}
}
</script>

<style
	lang="scss"
	module
>
.wrapper
{
	display: grid;
	font-family: 'Roboto', 'PT Sans', sans-serif;
	grid:
                "date calendar detail" 30px
                "periodType periodType detail" 30px
                "mealType mealType detail" 60px
                "meals meals detail" 1fr
            / 1fr 60px 250px;

	@media (min-height: 500px)
	{
		grid-template-rows: 60px 60px 80px 1fr;
		grid-template-columns: 1fr 60px 250px;;
	}

	@media (min-width: 900px)
	{
		grid-template-columns: 1fr 60px 350px;;
	}

	padding: 10px;
	gap: 10px 10px;
	overflow: hidden;


	.date
	{
		grid-area: date;
	}

	.calendar
	{
		grid-area: calendar;
		border-radius: 10px;
		border: 1px solid $greyBorder;
		background: $greyBackground url('~@/assets/img/calendar.svg') no-repeat center;
	}

	.periodType
	{
		grid-area: periodType;
		@media (max-height: 500px)
		{
			font-size: 16px;
		}
	}

	.mealType
	{
		grid-area: mealType;

		border-radius: 10px;
	}

	.meals
	{
		grid-area: meals;
	}

	.detail
	{
		grid-area: detail;
		font-size: 18px;
		@media (max-height: 500px)
		{
			font-size: 14px;
		}
	}

	.dateFilter
	{
		grid-row: 1 / 2;
		grid-column: 1 / 3;
	}

	.productCalendar
	{
		grid-row: 2 / 5;
		grid-column: 1 / 3;
	}
}

.menuCalendar
{
	display: grid;
	font-family: 'Roboto', 'PT Sans', sans-serif;
	grid: 60px 1fr / 1fr;
	@media (max-height: 500px)
	{
		grid-template-rows: 30px 1fr;
	}

	padding: 20px;
	gap: 10px 20px;
	overflow: hidden;
}

.loading:before
{
	content: "";
	position: absolute;
	top: 0;
	left: 0;

	height: 3px;
	width: 100vw;

	@keyframes animate-stripes
	{
		0%
		{
			background-position: 0 0;
		}
		100%
		{
			background-position: 100vw 0;
		}
	}

	background-image: linear-gradient(
			90deg,
			#40AE49 50%,
			transparent 50%
	);
	animation: animate-stripes 5s linear infinite;
}

.error
{
	margin: 20px;
	font-size: 20px;
	color: #FD470E;
}
</style>
