<template>
	<div :class="$style.menuBlock">
		<h3>
			<div>
				<span>+</span>
				Добавить в меню
				<span>+</span>
			</div>
		</h3>
		<p>Выберите блюда из категорий или воспользуйтесь поиском</p>
		<div :class="$style.search">
			<img src="~@/assets/img/search.svg"/>
			<input
				placeholder="Поиск"
				v-model="search"
			/>
			<button @click="setSearchNull"></button>
		</div>
		<div :class="$style.typeList">
			<template
				v-for="type in availableMealTypes"
				v-if="!search"
			>
				<button
					:class="activeType === type ? $style.active : ''"
					@click="toggleActiveType(type)"
				>
					<p>{{ type }}</p>
					<span></span>
				</button>
				<MealTypeListRight
					v-if="activeType === type"
					:meals="filteredByType"
					@detailMeal="setDetailProductId"
				/>
			</template>
			<MealTypeListRight
				v-if="search"
				:meals="filteredBySearch"
				@detailMeal="setDetailProductId"
			/>
		</div>
	</div>
</template>
<script>
import MealTypeListRight from '@/components/block/Rest/MealTypeListRight.vue'
import {mapMutations, mapActions, mapGetters} from "vuex";

export default {
	components: {MealTypeListRight},
	props: ['meals', 'types'],
	data()
	{
		return {
			search: '',
			activeType: null,
		}
	},
	computed: {
		...mapGetters('lkmenu', ['availableMealList', 'availableMealTypes']),
		filteredBySearch()
		{

			let reg = new RegExp(this.search, 'iu')
			return this.availableMealList.filter((el) =>
			{
				return el.name.match(reg) != null
			});
		},
		filteredByType()
		{
			return this.availableMealList.filter((el) =>
			{
				return el.type == this.activeType
			});
		}
	},
	methods: {
		...mapMutations('lkmenu', ['setDetailProductId']),
		setSearchNull()
		{
			this.search = '';
		},
		toggleActiveType(type)
		{
			if (this.activeType == type)
			{
				this.activeType = null;
			} else this.activeType = type;
		},
	}
}
</script>
<style
	lang="scss"
	module
>
.menuBlock
{
	background-color: $greyBackground;
	border-radius: 16px;
	overflow: hidden;
	display: grid;
	grid-template-rows: max-content max-content max-content 1fr;
	gap: 5px;
	padding: 0 5px;
	min-width: 0;
	padding-top: 10px;

	p
	{
		font-size: 12px;
		color: $greyText;
		text-align: center;
		line-height: 12px;
		@media (min-height: 500px)
		{
			line-height: 20px;
		}
	}
}

.typeList
{
	display: grid;
	gap: 10px;
	min-width: 0;
	overflow-y: auto;
	/*overflow-x: hidden;*/
	/*height: 100%;*/

	align-content: start;
	/*div{*/
	button
	{
		display: grid;
		grid-template-columns: 1fr 30px;
		width: 100%;
		height: 32px;
		border: none;
		border-radius: 4px;
		background-color: inherit;
		align-items: center;
		border-bottom: 1px solid $greyBorder;

		p
		{
			font-weight: bold;
			font-size: 16px;
			color: $textColor;
			text-align: left;
			margin-bottom: 0;
		}

		span
		{
			width: 24px;
			height: 30px;
			background-image: url('~@/assets/img/arrow.svg');

			transition: 0.1s linear all;
		}
	}

	.active
	{
		background-color: $lightGreenBackground;

		p
		{
			color: $green;
		}

		span
		{
			margin-top: -8px;
			transform: rotate(90deg);
		}
	}

	/*}*/
}

h3
{
	text-align: center;
	font-size: 18px;
	line-height: 20px;

	span
	{
		color: $green;
		width: min-content;
	}
}

.search
{
	height: 48px;
	background-color: white;
	border-radius: 8px;
	display: grid;
	grid-template-columns: 30px 1fr 20px;
	align-content: center;
	padding: 0 18px 0 15px;
	border: 1px solid $greyBorder;

	button
	{
		border: none;
		background-color: inherit;
		background-image: url('~@/assets/img/close.svg');
		width: 20px;
		height: 20px;
		margin-bottom: 5px;
		outline: none;
	}

	input
	{
		border: none;
		height: 100%;
		width: calc(100% - 60px);
		outline: none;
	}

	img
	{
		margin-top: 2px;
	}
}
</style>