<template>
	<div class="lk-menu_detail-meal">
		<div
			class="lk-menu_detail-photo"
			v-if="detailMeal.pictureDetail"
		>
			<div
				class="white-circle-icon-block"
				@click="setDetailProductId(null);$emit('closeProductCalendar');"
			>
				<div class="close-icon"></div>
			</div>

			<img
				class="image"
				:src=detailMeal.pictureDetail
			>

		</div>
		<div
			class="lk-menu_meal-name"
			v-if="detailMeal.name"
		>{{ detailMeal.name }}
		</div>
		<div
			class="lk-menu_weight"
			v-if="detailMeal.weight"
		>{{ detailMeal.weight }} гр
		</div>
		<div class="product-consist">
			<div
				class="product-consist__header"
				@click="showConsist=!showConsist"
			>
				<div class="product-consist__title">Состав продукта</div>
				<span :class="{'open':showConsist}"></span>
			</div>
			<div
				class="product-consist-items"
				v-if="showConsist"
			>
				<div
					ref="infoIcon"
					class="product-consist-items__text"
				>
					<div
						class="product-consist__item"
						v-for="(sostavItem, index) of this.consist"
					>{{ sostavItem }}
					</div>
				</div>
			</div>
		</div>
		<ProductNutrition
			:item="detailMeal"
			:showInfoIcon="true"
		/>
		<div
			v-if="showProductCalendar"
			class="batch"
		>
			<div class="batch-title">Подключить к выбранным датам на:</div>
			<div v-if="isSelected">
				<div
					class="lk-menu_toggle"
					:class="{active:enabled}"
					@click="enabled=!enabled; inLunch = enabled?inLunch:false  "
				>
					<span class="lk-menu_toggle-name">Активен</span>
					<div class="toggle-element">
						<div class="circle"></div>
					</div>
				</div>
				<div
					class="lk-menu_toggle"
					:class="{active:inLunch}"
					@click="inLunch=!inLunch; enabled = inLunch? true:enabled"
				>
					<span class="lk-menu_toggle-name">Добавить в конструктор</span>
					<div class="toggle-element">
						<div class="circle"></div>
					</div>
				</div>

				<button
					class="batch-btn submit"
					@click="updateBatch"
					:disabled="!isAllow"
				>Подтвердить
				</button>
			</div>
			<div v-else>
				<div class="batch-note">
					<svg
						width="32"
						height="22"
						viewBox="0 0 32 22"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M0.105982 11.4164L8.54028 21.0363C8.68163 21.3189 9.05245 21.548 9.36838 21.548H17.1701C17.4861 21.548 17.6277 21.3189 17.4864 21.0363L9.05215 11.4164C8.9108 11.1337 8.9108 10.6753 9.05215 10.3926L17.4864 0.963475C17.6278 0.680776 17.4861 0.45166 17.1701 0.45166H9.36844C8.7759 0.45166 8.41147 1.09578 8.07122 1.48848C7.62098 2.00828 1.36902 8.98046 0.105864 10.3926C-0.0353078 10.6755 -0.0353077 11.1336 0.105982 11.4164Z"
							fill="#66BE6D"
						/>
						<path
							d="M31.9454 0.963475C32.0868 0.680776 31.9451 0.45166 31.6291 0.45166C31.6291 0.45166 23.8274 0.45166 23.8273 0.45166C23.105 0.451838 22.156 1.90614 21.6847 2.43315C20.8554 3.36008 14.6102 10.3021 14.565 10.3928C14.4236 10.6755 14.4236 11.1339 14.565 11.4166L22.9993 21.0364C23.1407 21.3191 23.5114 21.5482 23.8274 21.5482H31.6291C31.9451 21.5482 32.0867 21.3191 31.9454 21.0364L23.5111 11.4166C23.3697 11.1339 23.3697 10.6755 23.5111 10.3928L31.9454 0.963475Z"
							fill="#66BE6D"
						/>
					</svg>
					<div>Выберите день/дни, чтобы включить блюдо в меню</div>

				</div>
				<button
					class="batch-btn"
					@click="$emit('closeProductCalendar')"
				>Отменить
				</button>
			</div>

		</div>
		<template v-else>
			<div class="lk-menu_toggles-block">
				<div
					class="lk-menu_toggle "
					:class="{active:detailMeal.enabled}"
					@click="update({enabled:!detailMeal.enabled})"
				>
					<span class="lk-menu_toggle-name">Активен</span>
					<div class="toggle-element">
						<div class="circle"></div>
					</div>
				</div>
				<div
					class="lk-menu_toggle"
					:class="{active:detailMeal.inLunch}"
					@click="update({inLunch:!detailMeal.inLunch})"
				>
					<span class="lk-menu_toggle-name">Добавить в конструктор</span>
					<div class="toggle-element">
						<div class="circle"></div>
					</div>
				</div>
				<div
					class="lk-menu_toggle"
					:class="{active:detailMeal.action}"
					@click="update({action:!detailMeal.action})"
				>
					<span class="lk-menu_toggle-name">Спецпредложение</span>
					<div class="toggle-element">
						<div class="circle"></div>
					</div>
				</div>
			</div>
			<div
				class="lk-menu_add-to-date-block"
				@click="$emit('showProductCalendar');"
			>
				<div class="calendar-icon"></div>
				<div class="text">Подключить к дате</div>
			</div>
		</template>
	</div>
</template>

<script>
import {mapGetters, mapActions, mapState, mapMutations} from "vuex";
import ProductNutrition from "../ProductNutrition";

export default {
	components: {ProductNutrition},
	data()
	{
		return {
			showConsist: false,
			enabled: false,
			inLunch: false
		}
	},
	props: ['showProductCalendar'],
	computed: {
		...mapGetters('lkmenu', ['detailMeal']),
		...mapState('lkmenu', ['currentDate', 'mealSelectedDays', 'batchActivity']),
		isSelected()
		{
			return Object.values(this.mealSelectedDays).length > 0
		},
		isAllow()
		{
			let dates = Object.keys(this.mealSelectedDays)

			if (dates.length === 0) return false

			for (let date of dates)
			{
				let act = this.batchActivity[date];
				if (act)
				{
					if (
						(act.enabled !== this.enabled) ||
						(act.inLunch !== this.inLunch)
					) return true
				} else
				{
					if (this.enabled || this.inLunch) return true
				}


			}


			return false
		},
		consist()
		{
			return this.detailMeal.sostav.split(',').filter(item => item.length > 0);
		}
	},
	methods:
		{
			...mapActions('lkmenu', ['updateActivity', 'updateBatchActivity']),
			...mapMutations('lkmenu', ['setDetailProductId']),
			async update(payload)
			{
				await this.updateActivity({
					enabled: this.detailMeal.enabled,
					...payload
				})
			},
			async updateBatch()
			{
				await this.updateBatchActivity({
					enabled: this.enabled,
					inLunch: this.inLunch
				})
				this.$emit('closeProductCalendar')
			}
		},
}
</script>

<style
	lang="scss"
	scoped
>
.lk-menu_detail-meal
{
	height: 100%;
	overflow: auto;
	background-color: $greyBackground;
	border-radius: 10px;
	padding: 12px;

	&::-webkit-scrollbar
	{
		width: 5px;

		&-thumb
		{
			background-color: darkgrey;
			border-radius: 3px;
		}
	}
}

.lk-menu_detail-photo
{


	border: 1px solid #E5E5E7;
	border-radius: 8px;
	background-color: #ffffff;
	position: relative;
	text-align: center;

	.image
	{
		max-height: 250px;
		@media (max-height: 500px)
		{
			max-height: 150px;
		}
		@media (max-width: 900px)
		{
			max-height: 150px;
		}
		border-radius: 8px;
		text-align: center;
	}

}

.lk-menu_meal-name
{
	width: 100%;
	font-style: normal;
	font-weight: bold;
	font-size: 1em;
	line-height: 1.1em;
	text-align: center;
	letter-spacing: 0.01em;
	color: #3D4248;
	margin-top: 16px;
	margin-bottom: 16px;
}

.lk-menu_weight
{
	font-style: normal;
	font-weight: normal;
	font-size: 0.9em;
	line-height: 1em;
	text-align: center;
	color: #979797;
	margin-bottom: 16px;
}

.lk-menu_toggle
{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 12px;
	border-bottom: 1px solid #E5E5E7;
}

.lk-menu_toggle-name
{
	float: left;
	min-width: 80px;
	font-style: normal;
	font-weight: bold;
	font-size: 1em;
	line-height: 1.2em;
	color: #3D4248;
	margin-right: 5px;
}

.lk-menu_toggle.active .lk-menu_toggle-name
{
	color: #40AE49;
}

.toggle-element
{
	min-width: 36px;
	width: 36px;
	height: 20px;
	background: #F8F8F8;
	border: 1px solid #E5E5E7;
	box-sizing: border-box;
	border-radius: 16px;
	cursor: pointer;
	padding: 1px;

	.circle
	{
		cursor: pointer;
		width: 16px;
		height: 16px;
		background: #FFFFFF;
		border-radius: 16px;
		box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 10px rgba(0, 0, 0, 0.1);
		transition: transform .1s ease-in-out;
	}
}

.lk-menu_toggle.active .toggle-element
{
	background: #66BE6D;

	.circle
	{
		transform: translate3d(16px, 0px, 0px);
	}
}

.lk-menu_add-to-date-block
{
	width: 100%;
	height: fit-content;
	background: #FFFFFF;
	border: 2px solid #F0F0F0;
	box-sizing: border-box;
	border-radius: 8px;
	padding: 12px 12px 12px 27px;
	cursor: pointer;
	position: relative;
	margin-top: 16px;
	margin-bottom: 16px;
}

.lk-menu_add-to-date-block .calendar-icon
{
	background-image: url(~@/assets/img/calendar.svg);
	width: 24px;
	height: 24px;
	display: inline-block;
	float: left;
	cursor: pointer;
}

.lk-menu_add-to-date-block .text
{
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 25px;
	text-align: center;
	color: #40AE49;
	cursor: pointer;
}

.white-circle-icon-block
{
	position: absolute;
	z-index: 2;
	right: -7px;
	top: -7px;
	border: 1px solid #F0F0F0;
	box-sizing: border-box;
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 10px rgba(0, 0, 0, 0.1);
	border-radius: 50%;
	width: 26px;
	height: 26px;
	text-align: center;
	cursor: pointer;
	background: #FFFFFF;
}

.close-icon
{
	background: url('~@/assets/img/close.svg');
	background-size: 20px;
	width: 20px;
	height: 20px;
	margin: 2px;
	/*cursor: pointer;*/
}

.batch
{
	&-title
	{
		font-size: 0.8em;
		text-align: center;
		color: #979797;

	}

	&-note
	{
		border: 2px dashed #E5E5E7;
		border-radius: 8px;
		padding: 1em;

		text-align: center;
		color: #979797;
		font-size: 1em;

		margin: 15px 0;


	}

	&-btn
	{
		display: block;
		width: 100%;
		color: #3D4248;
		font-size: 1em;
		line-height: 3em;

		background: #F0F0F0;
		border: 2px solid #E5E5E7;
		border-radius: 8px;

		&.submit
		{
			background: #66BE6D;
			border: 2px solid #66BE6D;
			color: white;

			&:disabled
			{
				border: 2px solid #E5E5E7;
				background: #F0F0F0;
				color: #3D4248;
			}

		}
	}
}

.product-consist
{
	margin-bottom: 14px;
	position: relative;
}

.product-consist__header
{
	position: relative;

	span
	{
		position: absolute;
		right: 0;
		top: -4px;
		width: 25px;
		height: 30px;
		background-size: 25px 30px;
		background-image: url('~@/assets/img/arrow.svg');
	}

	span.open
	{
		transform: rotate(90deg);
	}
}

.product-consist__title
{
	font-style: normal;
	font-weight: bold;
	text-decoration: underline;
	cursor: pointer;
	font-size: 15px;
	line-height: 20px;
	color: #40AE49;
	margin-bottom: 15px;
}

.product-consist-items
{
	margin-right: -6px;
	position: relative;
	overflow: hidden;

	.product__info-icon
	{
		position: absolute;
		bottom: 14px;
		right: 14px;
	}
}

.product-consist__item
{
	font-family: $mainFont;
	font-size: 13px;
	line-height: 100%;
	color: #3D4248;
	background-color: rgba(116, 118, 120, 0.1);
	padding: 7px 25px 0;
	height: 30px;
	border-radius: 60px;
	display: inline-block;
	margin-right: 6px;
	margin-bottom: 10px;
}
</style>
