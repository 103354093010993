var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"calendar"},[_vm._l((_vm.weekDays),function(weekDay){return _c('div',{key:weekDay,staticClass:"weekday"},[_vm._v(_vm._s(weekDay)+" ")])}),_vm._l((_vm.month),function(day){return _c('div',{key:day.format,staticClass:"calendar-day",class:{
                    'not-current-month-day':!day.isCurrent,
                    'selected':day.selected,
                    'hasAllLanches':day.hasAll,
                    'hasInactiveLunch':day.hasInactive,
                    'mealActive':day.isMealActive,
                },on:{"click":function($event){return _vm.handleDay(day)}}},[_c('span',{staticClass:"day-number"},[_vm._v(_vm._s(day.day))]),(day.hasInactive)?_c('div',{staticClass:"has-inactive-lanch"},[_vm._v("Конструктор не собран ")]):_vm._e(),(day.isMealLunch)?_c('span',{staticClass:"mealLunch"},[_vm._v("В конструкторе")]):_vm._e(),_c('div',{staticClass:"status-icon"})])})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }