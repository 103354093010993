<template>
	<div
		:class="$s.back"
		v-if="popup.show"
		@click.self="closePopup"
	>
		<div
			:class="$s.popup"
			:style="{top: popup.y+'px', left: popup.x+'px'}"
		>
			<div
				v-for="el in list"
				:class="{
                    [$s.row]:true,
                    [$s.active]:popupMeal[el.key]
                }"
				@click="update({[el.key]:!popupMeal[el.key]})"
			>
                <span :class="$s.checkbox">
                    <svg
						width="14"
						height="11"
						viewBox="0 0 14 11"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
                        <path
							d="M2 6.27778L5.04348 9L12 2"
							stroke="white"
							stroke-width="3"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
                    </svg>
                </span>
				{{ el.name }}
			</div>
		</div>
	</div>
</template>

<script>
import {mapMutations, mapState, mapGetters, mapActions} from "vuex";

export default {
	data()
	{
		return {
			list: [
				{name: 'Активен', key: 'enabled',},
				{name: 'Добавить в конструктор', key: 'inLunch'},
				{name: 'Спецпредложение', key: 'action'},
			]
		}
	},
	computed: {
		...mapState('lkmenu', ['popup']),
		...mapGetters('lkmenu', ['popupMeal'])
	},
	methods: {
		...mapActions('lkmenu', ['updateActivity']),
		...mapMutations('lkmenu', ['closePopup']),
		async update(payload)
		{
			await this.updateActivity({
				productId: this.popupMeal.id,
				enabled: this.popupMeal.enabled,
				...payload
			})
		}
	},
}
</script>

<style
	module="$s"
	lang="scss"
>
.back
{
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	z-index: 100;
	background-color: rgba(255, 255, 255, 0.5);
}

.popup
{
	border: 1px solid #F0F0F0;
	border-radius: 8px;
	position: absolute;
	background-color: white;
	padding: 10px;
	display: grid;
	gap: 8px;


	.row
	{
		height: 40px;
		line-height: 40px;
		background: #F3F3F3;

		border-radius: 8px;
		font-weight: 700;
		font-size: 14px;
		color: #3D4248;
		text-align: left;

		padding: 0 12px;

		cursor: pointer;
		user-select: none;

		.checkbox
		{
			display: inline-block;
			width: 24px;
			line-height: 24px;
			height: 24px;
			border-radius: 4px;
			border: 1px solid #F0F0F0;
			box-sizing: border-box;
			background: white;

			margin-right: 12px;
		}

		&.active
		{
			background: rgba(64, 174, 73, 0.2);
			color: #40AE49;

			.checkbox
			{
				background: #66BE6D;
				text-align: center;
			}
		}
	}
}

</style>