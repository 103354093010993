<template>
	<div class="wrapper">
		<div class="calendar">
			<div
				class="weekday"
				v-for="weekDay in weekDays"
				:key="weekDay"
			>{{ weekDay }}
			</div>
			<div
				class="calendar-day"
				:class="{
                    'not-current-month-day':!day.isCurrent,
                    'selected':day.selected,
                    'hasAllLanches':day.hasAll,
                    'hasInactiveLunch':day.hasInactive,
                    'mealActive':day.isMealActive,
                }"
				v-for="day in month"
				:key="day.format"
				@click="handleDay(day)"
			>
				<span class="day-number">{{ day.day }}</span>
				<div
					class="has-inactive-lanch"
					v-if="day.hasInactive"
				>Конструктор не собран
				</div>
				<span
					class="mealLunch"
					v-if="day.isMealLunch"
				>В конструкторе</span>
				<div class="status-icon"></div>
			</div>
		</div>
	</div>
</template>

<script>
import DateFilter from '@/components/block/Rest/DateFilter.vue'
import {mapMutations, mapState} from "vuex";
import qs from "qs";
import {Interval, DateTime} from 'luxon';

export default {
	components: {DateFilter},
	props: ['curDate', 'checkLunches', 'addProducts'],
	data()
	{
		return {
			weekDays: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],

			activeDates: {},
			mealActivity: {},
		}
	},
	computed:
		{
			...mapState('lkmenu', ['mealSelectedDays', 'detailProductId']),
			...mapState(['currentOwnerRestaurant']),
			month()
			{
				let current = this.curDate.month
				return Interval.fromDateTimes(
					this.curDate.startOf('month').startOf('week'),
					this.curDate.endOf('month').endOf('week')
				)
					.splitBy({day: 1})
					.map(d =>
					{
						let format = d.start.toISODate()

						let active = this.activeDates[format]
						let hasInactive = active && active.hasInactiveLunch
						let hasAll = active && !active.hasIncative
						return {
							date: d.start,
							format,
							selected: format in this.mealSelectedDays,
							isCurrent: d.start.month === current,
							day: d.start.day,
							hasInactive,
							hasAll,
							isMealActive: this.addProducts && (format in this.mealActivity),
							isMealLunch: this.addProducts && (format in this.mealActivity) && this.mealActivity[format].inLunch
						}
					})


			}
		},
	methods:
		{
			...mapMutations('lkmenu', ['toggleMealSelectedDays', 'clearMealSelectedDays', 'setBatchActivity']),
			handleDay(day)
			{
				if (this.checkLunches)
				{
					this.$emit('change', day.date)
				} else if (this.addProducts)
				{
					this.toggleMealSelectedDays(day.format)
				}
			},


			async getDatesActivityStatus()
			{
				let resp = await this.$axios.post(
					'/lkmenu/getDatesActivityStatus/',
					qs.stringify({
						currentOwnerRestaurant: this.currentOwnerRestaurant,
						dates: this.month.map(day => day.format)
					})
				);
				if (resp.data.success)
				{
					this.activeDates = resp.data.activeDates
				}
			},

			async getMealActivity()
			{
				let resp = await this.$axios.post(
					'/lkmenu/getBatch/',
					qs.stringify({
						currentOwnerRestaurant: this.currentOwnerRestaurant,
						productId: this.detailProductId,
						start: this.month[0].format,
						end: this.month[this.month.length - 1].format
					})
				);
				if (resp.data.success)
				{
					this.mealActivity = resp.data.activity
					this.setBatchActivity(resp.data.activity)
				}

			},


		},
	mounted()
	{
		this.clearMealSelectedDays()
		if (this.checkLunches)
		{
			this.getDatesActivityStatus()
		} else if (this.addProducts)
		{
			this.getMealActivity()
		}

	},
	watch: {
		curDate()
		{
			if (this.addProducts) this.getMealActivity()
			if (this.checkLunches) this.getDatesActivityStatus()
		}
	}
}
</script>

<style
	scoped
	lang="scss"
>
.wrapper
{
	background: #F8F8F8;
	border: 1px solid #F0F0F0;
	box-sizing: border-box;
	border-radius: 16px;
	padding: 24px;
	height: 100%;
	min-height: 0;
	@media (max-height: 500px)
	{
		padding: 8px 14px 14px 14px;
	}
}

.calendar
{
	display: grid;
	grid-template-rows: 0.2fr;
	grid-auto-rows: 1fr;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
	grid-gap: 0;
	height: 100%;
	border-bottom: 2px dashed #979797;


	.calendar-day
	{
		min-height: 0;
		border-top: 2px dashed #979797;
		border-left: 2px dashed #979797;

		.day-number
		{
			font-style: normal;
			font-weight: normal;
			font-size: 18px;
			line-height: 20px;
			color: #3D4248;
		}

		&:nth-child(7n)
		{
			border-right: 2px dashed #979797;
		}

		&.hasAllLanches
		{
			background-color: #66BE6D;
			position: relative;

			.day-number
			{
				color: #FFFFFF;
			}

			.status-icon
			{
				background-image: url('~@/assets/img/checkmark.svg');
				background-size: 23px;
				width: 23px;
				height: 23px;
				position: absolute;
				bottom: 5px;
				right: 5px;
			}
		}

		&.hasInactiveLunch
		{
			background-color: #FD470E;
			position: relative;

			.day-number
			{
				color: #FFFFFF;
			}

			.status-icon
			{
				background-image: url('~@/assets/img/alert.svg');
				background-size: 23px;
				width: 23px;
				height: 23px;
				position: absolute;
				bottom: 5px;
				right: 5px;
			}

			.has-inactive-lanch
			{
				display: inline-block;
				width: 60px;
				font-style: normal;
				font-weight: normal;
				font-size: 11px;
				line-height: 12px;
				float: right;
				text-align: right;
				color: #FFFFFF;
				padding: 5px;
				position: relative;
			}
		}

		&.not-current-month-day
		{
			.day-number
			{
				color: #979797;
			}

			&.hasInactiveLunch
			{
				background-color: #f4c8b9;
			}

			&.hasAllLanches
			{
				background-color: #DBECDC;
			}
		}

		&.mealActive
		{
			background-color: #DBECDC;
			position: relative;

			.mealLunch
			{
				position: absolute;
				bottom: 0;
				right: 5px;
				color: #66BE6D;
			}
		}

		&.selected
		{
			background-color: #66BE6D;

			.day-number,
			.mealLunch
			{
				color: white;
			}
		}
	}

	div
	{
		padding: 6px 8px;
	}

	.weekday
	{
		padding: 10px;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 20px;
		color: #979797;
	}
}
</style>