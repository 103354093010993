<template>
	<div :class="$style.periodType">
		<button
			v-for="period in periodType"
			:class="currentPeriodType == period.type ? $style.currentPeriodType : ''"
			@click="$emit('changeCurrentPeriodType', period.type)"
		>
			<div :class="$style.typeTitle">
				{{ period.name }}
			</div>
			<div :class="$style.typeTime">
				{{ period.time }}
			</div>
		</button>
	</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
	props: ['currentPeriodType'],
	data()
	{
		return {}
	},
	computed:
		{
			...mapGetters({
				deliveryTime: "lkmenu/getDeliveryTimes",
			}),
			periodType()
			{
				return [
					{
						type: "breakfast",
						name: "Завтрак",
						time: this.deliveryTime?.breakfast,
					},
					{
						type: "lunch",
						name: "Обед",
						time: this.deliveryTime?.lunch,
					},
					{
						type: "dinner",
						name: "Ужин",
						time: this.deliveryTime?.dinner,
					},
				];
			},
		},
}
</script>
<style
	lang="scss"
	module
>
.periodType
{
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 5px;
	align-items: center;
	border: 1px solid;
	border-color: $greyBorder;
	border-radius: 10px;
	background-color: $greyBackground;
	padding: 2px;
	height: 100%;

	button
	{
		/*cursor: not-allowed!important;*/
		/*opacity: 0.5;*/
		border: none;
		width: 100%;
		background-color: inherit;

		.typeTitle
		{
			font-weight: 700;
			font-size: 1em;
			color: $textColor;
			@media (max-height: 500px)
			{
				display: inline-block;
			}
			@media (max-width: 790px)
			{
				font-size: 12px;
			}
		}

		.typeTime
		{
			color: $greyText;
			font-size: 0.7em;
			margin-top: 5px;
			@media (max-height: 500px)
			{
				margin-left: 10px;
				display: inline-block;
			}
			@media (max-width: 790px)
			{
				font-size: 10px;
			}
		}
	}
}

.currentPeriodType
{
	background-color: white !important;
	background-color: $greenBackground;
	border-radius: 10px;
	height: 100%;
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 10px rgba(0, 0, 0, 0.12);

	.typeTitle
	{
		color: $green !important;
	}
}
</style>
