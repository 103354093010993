<template>
	<div :class="$style.wrapper">
		<div :class="$style.header">
			<div :class="$style.groupInfo">
				<span v-if="activeMealList.length === 0">Блюд не найдено</span>
				<template v-else-if="currentMealGroup">
					<span>{{ currentMealGroup }}</span>
					<span
						:class="$style.groupInfoCount"
						v-if="currentMealGroup in activeMealCount"
					>
                        {{ activeMealCount[currentMealGroup].all }}
                    </span>
					<span style="font-size: 12px; color:darkgrey">{{ a }}</span>
				</template>
			</div>
			<div :class="$style.switch">
				<span>Показать:</span>
				<div>
					<button
						@click="filterByLunch = false"
						:class="filterByLunch ?'':$style.active"
					>
						Все {{ activeMealList.length }}
					</button>
					<button
						@click="filterByLunch = true"
						:class="{
                                [$style.active]: filterByLunch,
                                [$style.warning]: warning,
                            }"
					>
						Конструктор {{ activeMealList.filter(el => el.inLunch).length }}
					</button>
				</div>
			</div>
		</div>

		<div
			:class="$style.mealsList"
			class="_meal-groups-wrapper"
		>
			<template v-for="meals,type,index in getFilteredMealsList">
				<div
					:class="$style.separator"
					v-if="index"
				>{{ type }}
				</div>
				<div
					:data-group="type"
					:data-index="index"
					class="_meal-group-title"
				></div>
				<MealRow
					v-for="el in meals"
					:source="el"
					:key="'meal-row-' + el.id"
				/>
			</template>
			<div :style="{height: end + 'px'}"></div>
		</div>

	</div>
</template>

<script>
import MealRow from '@/components/block/Rest/MealRow.vue'
import throttle from 'lodash/throttle'
import {mapMutations, mapState, mapActions, mapGetters} from "vuex";

export default {
	name: 'MealListOld',
	props: ['warning'],
	components: {MealRow},
	data()
	{
		return {
			itemComponent: MealRow,
			filterByLunch: false,
			a: '',
			end: 0,
			scrollTop: 0
		}
	},
	computed: {
		...mapState('lkmenu', ['currentMealGroup', 'intersectionProcess']),
		...mapGetters('lkmenu', ['activeMealList', 'activeMealCount']),
		getFilteredMealsList()
		{
			let meals = this.activeMealList, result = {};

			if (this.filterByLunch)
				meals = meals.filter(el => el.inLunch > 0);

			if (!meals.length)
				return result;

			meals.forEach(el =>
			{
				if (!result.hasOwnProperty(el.type))
					result[el.type] = [];
				result[el.type].push(el);
			});

			return result;
		},
		mealTypes()
		{
			return Object.keys(this.getFilteredMealsList);
		}
	},
	mounted()
	{
		setInterval(() =>
		{
			this.a = window.innerWidth + 'x' + window.innerHeight
		}, 200)

		let wrapper = document.querySelector('._meal-groups-wrapper').getBoundingClientRect();
		this.end = wrapper.height - 80

		this.scrollTop = wrapper.top;

		this.initMealGroupsIntersection();
	},
	methods: {
		...mapMutations('lkmenu', ['setCurrentMealGroup']),
		async initMealGroupsIntersection()
		{
			let observer = new IntersectionObserver(
				this.handleScroll,
				{
					root: document.querySelector('._meal-groups-wrapper'),
					rootMargin: '0px',
					threshold: 0.5
				}
			);

			document.querySelectorAll('._meal-group-title').forEach(group => observer.observe(group));
		},
		async handleScroll(entries, observer)
		{
			entries.forEach(async el =>
			{

				if (this.intersectionProcess) return;

				let rect = el.boundingClientRect;
				if (Math.abs(this.scrollTop - rect.top - rect.height / 2) <= 300)
				{
					let index = el.target.dataset.index;

					if (el.isIntersecting)
						index = Math.max(0, Math.min(el.target.dataset.index - 1, this.mealTypes.length - 1));

					await this.setCurrentMealGroup(this.mealTypes[index]);
				}
			});
		}
	},
	watch: {
		async getFilteredMealsList(list)
		{
			await this.$nextTick();
			this.initMealGroupsIntersection();
		},
	}
}
</script>


<style
	lang="scss"
	module
>
.separator
{
	padding-top: 10px;
	font-size: 18px;
	font-weight: 700;

	span
	{
		color: $green;
		margin-left: 10px;
	}
}

.wrapper
{
	display: grid;
	grid-template-rows: max-content 1fr;
	min-height: 0;
}

.header
{
	display: grid;
	grid-template-columns: 2fr 1fr;

	.groupInfo
	{
		font-size: 18px;
		font-weight: 700;

		span
		{
			margin-right: 10px;
		}
	}

	.groupInfoCount
	{
		color: $green;
	}

	.switch
	{
		justify-self: end;
		display: grid;
		grid-template-columns: 55px 205px;
		gap: 8px;

		span
		{
			width: fit-content;
			font-size: 12px;
			line-height: 28px;
			box-sizing: border-box;
			font-weight: 400;
			color: $greyText;
		}

		div:not(.title)
		{
			height: 28px;
			display: inline-grid;
			justify-items: center;
			grid-template-columns: 1fr 1fr;
			background: $greyBackground;
			border: 1px solid $greyBorder;
			border-radius: 100px;
			padding: 2px;


			button
			{
				background: inherit;
				border: none;
				color: $greyText;
				font-size: 13px;
				font-weight: 700;
				border-radius: 100px;
				padding: 0;
				width: 100%;
			}

			.active
			{
				background: white;
				color: $green;
				box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.08);
			}

			.warning
			{
				color: #FD470E;
			}

		}
	}
}

.mealsList
{
	margin: 0 -10px -10px -10px;
	padding: 10px;
	overflow-y: auto;
	display: grid;
	gap: 8px;
}


</style>