<template>
	<div
		:class="[$style.row, detailProductId === source.id?$style.current:'']"
		@click="setDetailProductId(source.id)"
	>
		<img :src="source.picture"/>
		<div :class="$style.info">
			<div :class="$style.name">{{ source.name }}</div>
			<div :class="$style.weight">{{ source.weight }} гр.</div>
		</div>
		<div :class="$style.stats">
			<div :class="[$style.inactiveStats, source.action > 0 ? $style.actionActive : '']">
				<div>Акция</div>
			</div>
			<div :class="[$style.inactiveStats, source.inLunch > 0 ? $style.lunchActive : '']"><span>Конструктор</span>
			</div>
		</div>
		<span
			:class="$style.points"
			@click.stop="showPopup({event:$event, productId:source.id})"
		></span>
	</div>
</template>

<script>
import {mapMutations, mapState, mapGetters} from "vuex";

export default {
	props: {
		index: {
			type: Number
		},
		source: {
			type: Object,
			default()
			{
				return {}
			}
		},
	},
	computed: {
		...mapState('lkmenu', ['detailProductId']),
		...mapGetters('lkmenu', ['activeMealCount']),
	},
	methods: mapMutations('lkmenu', ['setDetailProductId', 'showPopup']),

}
</script>
<style
	lang="scss"
	module
>
.separator
{
	padding-top: 10px;
	font-size: 18px;
	font-weight: 700;

	span
	{
		color: $green;
		margin-left: 10px;
	}
}


.row
{
	border: 2px solid white;
	border-radius: 8px;
	padding: 10px;
	font-size: 14px;
	cursor: pointer;
	box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.08);

	display: grid;
	grid-template-columns: 60px 1fr 190px 30px;
	@media (max-width: 767px)
	{
		grid-template-columns: 60px 75px 190px 30px;
	}

	&.current
	{
		border: 2px solid $green;
	}

	.name
	{
		font-family: $mainFontMedium;
		font-style: normal;
		font-weight: bold;
		line-height: 120%;
	}

	.weight
	{
		font-family: $mainFontMedium;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 14px;
		letter-spacing: 0em;
		text-align: left;
		color: $greyText;
		margin-top: 5px;
		@media (max-width: 767px)
		{
			font-size: 12px;
		}
	}

	.info
	{
		margin: auto;
		margin-left: 0;
		@media (max-width: 767px)
		{
			font-size: 12px;
		}
	}

	.stats
	{
		display: grid;
		gap: 5px;
		grid-template-columns: 75px 110px;
		justify-content: center;
		align-content: center;
		font-size: 12px;

		div
		{
			margin: auto;
			width: max-content;
		}

		.inactiveStats
		{
			color: $greyLightText;
			padding: 3px 15px;
			width: fit-content;
			background-color: inherit;
			border-radius: 8px;
			border: 2px solid $greyBorder;
			transform: skewX(-15deg);
			opacity: 0.5;
		}

		.actionActive
		{
			color: white;
			background-color: $yellow;
			opacity: 1;
			border-color: white;
		}

		.lunchActive
		{
			color: white;
			background-color: $green;
			opacity: 0.8;
			border-color: white;
		}
	}

	.points
	{
		height: 24px;
		width: 24px;
		margin: auto;
		background-image: url('~@/assets/img/3points.svg');
	}

	button
	{
		border: none;
		background-color: inherit;
		width: 40px;
		height: 40px;
		margin: 6px 10px;
		outline: none;
	}

	img
	{
		width: 54px;
		height: 48px;
		object-fit: contain;
	}
}

@media (max-width: 768px)
{
	.row
	{
		font-size: 12px;
	}
}
</style>
